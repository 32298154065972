#CoffeeScript

{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'
{waitscreen} = require 'src/js/infrastructure'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'
{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx, promise) ->
    $saveChanges = $ctx.find('#save-changes')
    $grid = $ctx.find('#vehicle-fleet').staticgrid()[0]

    $grid.editor.on 'initEdit', ->
        $grid.editor.hide('isInUse')

    $grid.editor.on 'initCreate', ->
        $grid.editor.hide('isInUse')

    $saveChanges.on 'click', ->
        url = $saveChanges.data 'url'
        states = $grid.state.changes()
        data =
            states: states
            entityId: $saveChanges.data 'entity-id'
        promise = antiForgeryPost url, data
            .then makeRefreshHandler()
            .then makeSuccessHandler $saveChanges
            .fail makeErrorHandler $saveChanges

        waitscreen.waitFor promise

    $grid.table.on 'change.selection', (event) ->
        if event.rows.length <= 0
            return
        deleteBtn = $('#vehicle-fleet_wrapper').find('.buttons-remove')
        if event.rows[0].isInUse then deleteBtn.disable() else deleteBtn.enable()

    $grid.editor.on 'preOpen', ->
        vinValidationMsg = $('#vin-validation-msg').data('rule-vin-validation-msg')
        requiredMsg = $('#vin-validation-msg').data('rule-required-msg')
        $form = $($grid.editor.dom.form)
        validator = $form.validate()
        $form.data 'validator', validator
        $form.clearValidation()

        $($grid.editor.field('vin').node().querySelector('input')).on 'keyup', (event) ->
            this.value = this.value.toUpperCase()

        $grid.editor.field('vin').node().querySelector('input').setAttribute('data-rule-required', true)
        $grid.editor.field('vin').node().querySelector('input').setAttribute('name', 'vin')
        $grid.editor.field('vin').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

        $grid.editor.field('name').node().querySelector('input').setAttribute('data-rule-required', true)
        $grid.editor.field('name').node().querySelector('input').setAttribute('name', 'name')
        $grid.editor.field('name').node().querySelector('input').setAttribute('data-msg-required', requiredMsg)

    $grid.editor.on 'preSubmit', (e) ->
        formIsValid = $($grid.editor.dom.form).valid()
        unless formIsValid
            e.preventDefault()
            return false

register('App.VehicleFleet.Setup', setup)
